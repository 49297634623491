<template>
  <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="30%"
      center>
    <span>{{ content }}</span>
    <span slot="footer" class="dialog-footer">
    <el-button @click="centerDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="save">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
export default {
  name: "DeleteConfirm",
  props:{
    content: {
      type: String,
      default: "",
    },
    pStata:{
      content: {
        type: String,
        default: '1',
      },
    }
  },
  data(){
    return{
      centerDialogVisible:false
    }
  },
  methods:{
    showEdit(){
      this.centerDialogVisible = true
    },
    save(){
      this.centerDialogVisible = false
      this.$emit('save')
    }
  }
}
</script>

<style  lang="less">

</style>
